@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$smartdp-search-marketing-color: $black !default;

.home-layout {
	position: relative;

	&__image {
		position: relative;

		&--commercial {
			position: absolute;
			top: 0;
			width: 100%;
		}

		&-background {
			height: 100%;
		}

		&-headline {
			position: relative;
			margin-top: -256px;

			@include gridle_state(md) {
				position: absolute;
				width: 100%;
				height: 100%;
				justify-content: center;
				display: flex;
				flex-direction: column;
				top: 0;
				margin-top: 0;
			}
		}
	}

	.smartdp-marketing-headline {
		padding: 0 16px 24px;

		@include gridle_state(md) {
			padding: 0 0 24px;
			max-width: 1024px;
			margin: 0 auto;
		}

		&__title {
			font-family: var(--font-family-bold-xl5);
			font-size: var(--font-size-bold-xl5);
			font-weight: var(--font-weight-bold-xl5);
			text-transform: var(--text-transform-bold-xl5);

			@include gridle_state(md) {
				font-family: var(--font-family-bold-xl6);
				font-size: var(--font-size-bold-xl6);
				font-weight: var(--font-weight-bold-xl6);
				text-transform: var(--text-transform-bold-xl6);
			}
		}
	}

	.smartDP-hero-commercial__image-headline {
		.smartdp-marketing-headline__title {
			@include gridle_state(md) {
				font-family: var(--font-family-bold-xl9);
				font-size: var(--font-size-bold-xl9);
				font-weight: var(--font-weight-bold-xl9);
				text-transform: var(--text-transform-bold-xl9);
			}
		}
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&__form {
		margin: 0 16px 6px;

		@include gridle_state(md) {
			max-width: 1024px;
			margin: 0 auto;
			width: 100%;
		}
	}

	&__marketing {
		color: white;
		text-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
		line-height: 1;

		@include gridle_state(md) {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}

	&__marketing-container {
		position: absolute;
		height: 130px;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(51, 51, 51, 0),
			$smartdp-search-marketing-color 100%
		);
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 15px;

		@include gridle_state(md) {
			background: transparent;
			z-index: unset;
		}
	}

	em {
		font-style: italic;
	}

	&__container {
		&--custom-header {
			position: relative;
		}
	}

	&__dynamic-blocks {
		margin-top: 0;
		padding-top: 0;

		.dynamic-blocks-renderer {
			&__item {
				@include container;

				&--full-width {
					max-width: unset;
					width: 100%;
				}

				> div {
					box-sizing: border-box;
					padding: 32px 16px;

					@include gridle_state(md) {
						padding: 48px 0;
					}
				}

				.payment-description {
					padding: 16px;

					@include gridle_state(md) {
						padding-left: 0;
						padding-right: 0;
					}
				}

				.membership {
					margin-top: 24px;
					margin-bottom: 24px;
					padding: 24px 0;

					&__title,
					&__all-sales {
						padding-right: 16px;
						padding-left: 16px;
					}

					&__offers {
						padding-left: 16px;
						box-sizing: border-box; // sans ca, toute la page sur mobile peut se deplacer lateralement
					}

					@include gridle_state(md) {
						margin-top: 16px;
						margin-bottom: 16px;
						padding: 32px 0;

						&__title,
						&__all-sales {
							padding-left: 0;
							padding-right: 0;
						}

						&__offers {
							padding-left: 0;
						}
					}
				}

				.merch-carousel-block {
					padding: 24px 0 24px 16px;

					&__title {
						margin-right: 16px;
					}

					@include gridle_state(md) {
						padding: 32px 0;

						&__title {
							margin-right: 0;
						}
					}
				}

				.product-list-block {
					&__title,
					&__view-all-offers-button {
						margin-right: 16px;
					}

					.product-list {
						margin-right: -16px;
						margin-left: -16px;
					}

					@include gridle_state(md) {
						padding: 32px 0;

						&__title,
						&__view-all-offers-button,
						.product-list {
							margin-right: 0;
							margin-left: 0;
						}
					}
				}

				.sdp-reasons {
					padding-right: 0;

					.reason-to-book-block {
						padding: 0;
					}
				}
			}
		}
	}

	&__loader {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;

		@include gridle_state(md) {
			@include container;
		}
	}
}
